<template>
  <UserDetailItemEditCard confirmation-message="Wachtwoord werd succesvol gewijzigd"
                      title="Bewerk wachtwoord"
                      :detailViewRouteLocation="{name: routeNames.USER_DETAIL, params: { userId: userId}}"
                      update-store-action="authModule/updateOwnPassword"
                      :update-store-action-payload="payload"
                      :userId="userId">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12">
          <v-text-field :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        :type="showPassword ? 'text' : 'password'"
                        v-model.trim="oldPassword" label="Huidig wachtwoord"
                        :rules="passwordRules"
                        required persistent-placeholder/>
        </v-col>
      </v-row>
      <PasswordInput cols="6" v-model="newPassword" persistent-placeholder/>
    </template>
  </UserDetailItemEditCard>
</template>

<script>
import {getPasswordRules} from "@/shared/utils/inputRulesUtils";
import RouteNames from "@/router/RouteNames";
import PasswordInput from "@/components/useroverview/PasswordInput.vue";
import UserDetailItemEditCard from "@/views/user/forms/UserDetailItemEditCard.vue"

export default {
  name: "PasswordReset",
  components: {
    PasswordInput,
    UserDetailItemEditCard
  },
  data() {
    return {
      valid: false,
      showPassword: false,
      oldPassword: null,
      newPassword: null,
      passwordRules: getPasswordRules(),
      routeNames: RouteNames,
      userId: this.$store.getters["authModule/getUserId"],
      
    }
  },
  computed: {
    payload() {
      return {
        email: this.$store.state.authModule.user?.email,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      }
    }
  }
}
</script>
